import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import React from "react";
import config from "../../config.json";
import Layout from "../component/Layout/Layout";
import BlogPosts from "../component/Posts/BlogPosts";
import StickyBox from "../component/StickyBox/StickyBox";
import "../assets/styles/article-page.sass";
import "../assets/styles/blog-page.sass";
import CompareWidget from "../component/CompareWidget/CompareWidget";
import {graphql} from "gatsby";
import DateSvg from "../assets/images/icons/calendar-article.svg";
import UpdateSvg from "../assets/images/icons/arrows-article.svg";
import Heading from "../component/Heading";
import Seo from "../component/Seo/Seo";

const Post = ({pageContext, data}) => {
  const post = pageContext.data;

  // const next = pageContext.next
  // const previous = pageContext.previous

  const pageQueryData = data;
  const image = pageQueryData.wpPost.featuredImage ? getImage(pageQueryData.wpPost.featuredImage.node.localFile) : null;
  const description = post.zajawkablog.zajawka ? post.zajawkablog.zajawka : null;
  const imageMeta = image ? image.images.fallback.src : null;
  const prefix = pageContext.type === "blog" ? "blog-emerytalny" : "faq";
  
  return (
    <Layout>
      <Seo
        title={post.seo.title}
        description={post.seo.metaDesc}
        keywords={post.seo.focuskw}
        article={true}
        pathname={`/${prefix}/${post.slug}/`}
        image={imageMeta}
      />
      <div className="hero-section">
        {image ? (
          <GatsbyImage image={image} alt="" />
        ) : (
          <StaticImage
            src="../assets/images/photo/summer-rest.jpg"
            width={1920}
            height={450}
            quality={90}
            transformOptions={{fit: "cover", cropFocus: "attention"}}
            alt="Morze, emerytura"
            title=""
          />
        )}
        <div className="container container-absolute d-none d-xl-block">
          <div className="title-box">
            <Heading
              title={post.title}
              showUnderline={false}
              maxWidth="500px"
              margin="0 0 20px 0"
              align="flex-start"
              h={1}
            />
            <div className="line"></div>
            {description && <div className="description">{description}</div>}
          </div>
        </div>
      </div>
      <div className="container mw-1400">
        <div className="row">
          <div className="col-xl-8 article">
          <div className="d-block d-xl-none">
            <Heading
              title={post.title}
              showUnderline={false}
              maxWidth="100%"
              margin="0 0 20px 0"
              align="flex-start"
              h={1}
            />
            <div className="line"></div>
            {description && <div className="description mb-4">{description}</div>}
          </div>
            <div className="post-info">
              <div className="info">
                <div className="date-wrapper">
                  <img src={DateSvg} alt="" />
                  <span className="date">{post.dateGmt}</span>
                </div>
                <div className="date-wrapper">
                  <img src={UpdateSvg} alt="" />
                  <span className="date">{post.modifiedGmt}</span>
                </div>
              </div>
            </div>
            <div className="content" dangerouslySetInnerHTML={{__html: post.content}}></div>
            <div className="author">
              <img src={post.author.node.avatar ? post.author.node.avatar.url : 'https://secure.gravatar.com/avatar/94c45a27eb52b5d4e16e3fb4d4269c37?s=96&d=mm&r=g'} title="" alt="" />
              <span className="name">
                {post.author.node.seo.title} <br />
                <span className="description">{post.author.node.description}</span>
              </span>
            </div>
          </div>
          <div className="col-xl-4">
            <StickyBox>
              <CompareWidget isNarrow={true} />
            </StickyBox>
          </div>
        </div>
      </div>
      <BlogPosts />
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query BlogPost($id: String) {
    wpPost(id: {eq: $id}) {
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, height: 600, quality: 90)
            }
          }
        }
      }
    }
  }
`;
